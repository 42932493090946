<script lang="ts" setup>
import type { Opportunity } from "@/types";

const { open } = useAppModals();
const isGuest = useIsGuest();
const router = useRouter();
const { t } = useT();

const { collections } = useHomePage();

const { homePromotions } = usePromotionsData();

const opportunity: Opportunity[] = [
	{
		icon: "oportunity-games",
		type: "games",
		class: "games",
		link: "/issues/all-games/",
		title: t("Games"),
		isNew: true,
		list: [],
		showActiveMark: true
	},
	{
		icon: "oportunity-promotions",
		type: "promotions",
		class: "promotions",
		link: "/promotions/",
		title: t("Promotions"),
		isNew: false,
		list: homePromotions?.value?.promotions || [],
		showActiveMark: true
	},
	{
		icon: "oportunity-tournaments",
		type: "tournaments",
		class: "tournaments",
		link: "/tournaments/",
		title: t("Tournaments"),
		isNew: false,
		list: [],
		showActiveMark: false
	},
	{
		icon: "oportunity-collections",
		type: "collections",
		class: "collections",
		link: "/issues/collections/",
		title: t("Collections"),
		isNew: false,
		list: collections?.value || [],
		showActiveMark: true
	}
];

const handleClick = (opportunity: Opportunity) => {
	if (isGuest.value && ["promotions", "tournaments"].includes(opportunity.type)) {
		open("LazyOModalSignup");
		return;
	}
	router.push(opportunity.link);
};
</script>

<template>
	<div class="oportunity">
		<div v-for="(item, i) in opportunity" :key="i" :class="`oportunity__item ${item.class}`" @click="handleClick(item)">
			<div class="oportunity__icon">
				<NuxtIcon :name="`40/${item.icon}`" class="icon" filled />
			</div>
			<AText variant="taipei" :modifiers="['semibold']">
				<p class="oportunity__title">{{ item.title }}</p>
			</AText>
			<AText v-if="item.showActiveMark" variant="taipei" :modifiers="['semibold']" class="text-сirebon">
				<p class="oportunity__title">{{ item.type === "games" ? "100+" : item.list.length }}</p>
			</AText>
			<ABadge v-if="item.isNew" class="oportunity__badge" variant="info" autosize background="var(--carabanchel)">
				<AText variant="topeka" :modifiers="['medium', 'uppercase']" class="text-coro">{{ t("New") }}</AText>
			</ABadge>
			<ASvg name="32/arrow-right" class="icon oportunity__arrow" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes jumping {
	25% {
		opacity: 0;
	}
	50% {
		transform: scale(1.3);
		opacity: 1;
	}
	75% {
		opacity: 0.7;
	}
}
@keyframes swingAndScale {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.2);
	}

	50% {
		transform: rotate(-15deg);
	}

	75% {
		transform: rotate(15deg);
	}
}
.oportunity {
	position: relative;
	z-index: 3;
	width: 1312px;
	max-width: calc(100% - 32px);
	margin: 24px auto 0;
	display: flex;
	justify-content: space-between;
	gap: 8px;

	@media (max-width: 1150px) {
		flex-wrap: wrap;
	}

	@include media-breakpoint-down(md) {
		margin: 50px auto 0;
	}

	&__item {
		position: relative;
		width: 25%;
		display: flex;
		align-items: center;
		gap: 8px;
		height: 72px;
		border-radius: 16px;
		padding: 0 12px;

		@media (max-width: 1150px) {
			width: calc(50% - 4px);
		}

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		&:hover {
			cursor: pointer;
		}

		&.collections {
			background: var(--tertiary-900);
		}

		&.tournaments {
			background: var(--success-900);
		}

		&.games {
			background: var(--secondary-800);
		}

		&.promotions {
			background: var(--chittaurgarh);
		}

		&:nth-child(1),
		&:nth-child(3) {
			.oportunity__icon:deep(svg) {
				animation: swingAndScale 3s ease-in-out infinite;
			}
		}

		&:nth-child(2),
		&:nth-child(4) {
			.oportunity__icon:deep(svg) {
				animation: swingAndScale 3s ease-in-out 1.5s infinite;
			}
		}
	}

	&__icon {
		width: 40px;
		height: 40px;

		:deep(svg) {
			font-size: 40px;
		}
	}
	&__badge {
		padding: 8px 12px !important;

		border-radius: 999px;
		border: 2px solid var(--chifeng);
	}
	&__arrow {
		font-size: 30px;
		margin-left: auto;
	}
}
</style>
